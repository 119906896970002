import '../../common/js/init_sentry'
import '../scss/main.scss';

import 'babel-polyfill';
import './shim-semantic-ui';

import './bootstrap';

$(document).ready(function () {
});
