import axios from "axios";

class VoteForm {
    form;
    /**
     * @param {HTMLFormElement} form
     */
    constructor(form) {
        this.form = form;
        this.autoSubmited = false;
    }

    autoSubmit() {
        if (this.autoSubmited) {
            return;
        }
        setInterval(() => {
            this.submit();
        }, 30000);

        this.autoSubmited = true;
    }

    getAction() {
        return this.form.action;
    }

    submit() {
        axios.post(this.getAction(), new FormData(this.form), {
            headers: {
                "x-api": true,
            }
        }).then((response) => {
            console.log(response);
        });
    }

}

export default VoteForm;