import { Controller } from '@hotwired/stimulus';
import VoteForm from "../component/VoteForm";
import { connectStreamSource, disconnectStreamSource } from "@hotwired/turbo";

export default class extends Controller {
    static values = {hubUrl: String}

    connect() {
        this.es = new EventSource(this.hubUrlValue);
        connectStreamSource(this.es);

        this.es.addEventListener('eventStarted', (event) => {
            const data = JSON.parse(event.data);
            window.location.replace(`/vote/${data.eventId}`)
        })
    }

    disconnect() {
        this.es.close();
        disconnectStreamSource(this.es);
    }


}
